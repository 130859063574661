/*
 * @Author: 孙兴隆
 * @description 接口文件
 */
import request from '../../../../api/index';

export default class {
  //获取tree列表
  static getTreeList = () => {
    return request('get', `article/category/index`)
  }
  //获取分类数据列表
  static getDateList = (params) => {
    return request('post', `article/index`, params)
  }
  //删除列表接口
  static DeleteDateList = (params) => {
    return request('get', `article/delete`, params)
  }
}

