/*
 * @Author: 孙兴隆
 * @description 接口文件
 */
import request from '~api/index';

export default class {
  //获取分类数据列表
  static UpLoadFile = (params, Upload) => {
    return request('post', `upload/uploadImage`, params, Upload)
  }

}

