/*
 * @Author: 孙兴隆
 * @description 项目根目录
 */


import React from 'react';          //  jsx写法必须引入React否则报错
import ReactDOM from 'react-dom';
import App from './App';
import 'antd/dist/antd.css';
import './assets/css/conmon.css'
import * as stores from './config/store'     //引入全部的mod文件
import {Provider} from 'mobx-react'         //将所有的mod文件注入组件中
import {HashRouter as Router} from "react-router-dom";

//注册一个全局函数
window.app = {}


ReactDOM.render(
  <Provider {...stores}>
    <Router>
      <App/>
    </Router>
  </Provider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
