/*
 * @Author: 孙兴隆
 * @description 接口文件
 */
import request from '../../../../api/index';

export default class {
  //生成模块
  static CreatModule = (params) => {
    return request('post', `module/create`, params)
  }
  //删除列表接口
  static DeleteDateList = (params) => {
    return request('get', `module/delete`, params)
  }
}

