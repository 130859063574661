import request from '~api/index';

export default class {
  // 删除
  static deleteData = (params) => {
    return request('delete', `page/delete`, params)
  }

  // 生成页面
  static generate = (params) => {
    return request('post', `page/generate`, params)
  }
}