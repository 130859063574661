/*
 * @Author: 孙兴隆
 * @description Mod层处理函数数据
 */

import {observable, action, toJS} from 'mobx'
import Serv from './ContentMainEditServ'
import {message} from "antd/lib/index";

class ContentMainEditMod {
  @observable state = {}

  @action EditDateList = async (params) => {
    try {
      const {data, data: {statusCode}} = await Serv.EditDateList(params);
      if (statusCode == 1) {
        return data;
      } else {
        message.error(data.message)
      }
    } catch (error) {
      console.log(error)
    }
  }
  @action SaveDateList = async (params) => {
    try {
      const {data, data: {statusCode}} = await Serv.SaveDateList(params);
      if (statusCode == 1) {
        message.success(data.message)
        return true;
      } else {
        message.error(data.message)
      }
    } catch (error) {
      console.log(error)
    }
  }
}

const contentMainEidtMod = new ContentMainEditMod()
export default contentMainEidtMod
