/*
 * @Author: 孙兴隆
 * @description 路由跟目录，获取组件并渲染路由
 */


import React from 'react';
import {Route, Switch, Redirect} from "react-router-dom";
import lazyLoad from './lazyLoad'       //懒加载组件

let route = [
  {
    path: '/Login',
    Component: lazyLoad(() => import('../components/Login/Login'))
  },
  {
    path: '/Home',
    Component: lazyLoad(() => import('../pages/Demo/home/home'))
  },
  {
    path: '/TableDemo',
    Component: lazyLoad(() => import('../pages/Demo/table/table'))
  },
  {
    path: '/AllFormDemo',
    Component: lazyLoad(() => import('../pages/Demo/FormDemo/FormDemo'))
  },
  {
    path: '/EditorDemo',
    Component: lazyLoad(() => import('../pages/Demo/EditorDemo/EditorDemo'))
  },
  {
    path: '/IconDemo',
    Component: lazyLoad(() => import('../pages/Demo/IconFont/IconFont'))
  },
  {
    path: '/FormDesignDemo',
    Component: lazyLoad(() => import('../pages/Demo/Template/View'))
  },
  {
    path: '/403',
    Component: lazyLoad(() => import('../components/Warning/403'))
  },
  {
    path: '/404',
    Component: lazyLoad(() => import('../components/Warning/404'))
  },
  {
    path: '/500',
    Component: lazyLoad(() => import('../components/Warning/500'))
  },
  {
    path: '/Error',
    Component: lazyLoad(() => import('../components/Results/Error'))
  },
  {
    path: '/Success',
    Component: lazyLoad(() => import('../components/Results/Success'))
  },
  // {
  //   path: '/Tree',
  //   Component: lazyLoad(() => import('../components/Tree/Tree'))
  // },
  {
    path: '/TestDemo',
    Component: lazyLoad(() => import('../pages/TestDemo'))
  },
  {
    path: '/AccountList',
    Component: lazyLoad(() => import('../pages/Admin/Account/AccountList/AccountList'))
  },
  {
    path: '/account_edit',
    Component: lazyLoad(() => import('../pages/Admin/Account/AccountEdit/AccountEdit'))
  },
  {
    path: '/role_list',
    Component: lazyLoad(() => import('../pages/Admin/Role/RoleList/RoleList'))
  },
  {
    path: '/role_edit',
    Component: lazyLoad(() => import('../pages/Admin/Role/RoleEdit/RoleEdit'))
  },
  {
    path: '/system_menu_edit',
    Component: lazyLoad(() => import('../pages/Admin/systemMenu/systemMenuEdit/systemMenuEdit'))
  },
  {
    path: '/system_menu_list',
    Component: lazyLoad(() => import('../pages/Admin/systemMenu/systemMenuList/systemMenuList'))
  },
  {
    path: '/property_list',
    Component: lazyLoad(() => import('../pages/TemplateMgmt/Property/PropertyList/PropertyListView'))
  },
  {
    path: '/property_edit',
    Component: lazyLoad(() => import('../pages/TemplateMgmt/Property/PropertyEdit/PropertyEditView'))
  },
  {
    path: '/article_category',
    Component: lazyLoad(() => import('../pages/Content/ArticleCategory/ArticleCategory'))
  },
  {
    path: '/content_main',
    Component: lazyLoad(() => import('../pages/Content/ContentMain/ContentMainList/ContentMain'))
  },
  {
    path: '/content_main_eidt',
    Component: lazyLoad(() => import('../pages/Content/ContentMain/ContentMainEdit/ContentMainEdit'))
  },
  {
    path: '/template',
    Component: lazyLoad(() => import('../pages/TemplateMgmt/ManageMent/Template/Template'))
  },
  {
    path: '/new_edit_template',
    Component: lazyLoad(() => import('../pages/TemplateMgmt/ManageMent/NewEditTemplate/NewEditTemplate'))
  },
  {
    path: '/module_manage',
    Component: lazyLoad(() => import('../pages/PageSetUp/ModuleManage/ModuleManageList/ModuleManageList'))
  },
  {
    path: '/module_manage_edit',
    Component: lazyLoad(() => import('../pages/PageSetUp/ModuleManage/ModuleManageEdit/ModuleManageEdit'))
  },
  {
    path: '/page_manage',
    Component: lazyLoad(() => import('../pages/PageSetUp/PageManage/PageManageList/PageManageList'))
  },
  {
    path: '/page_manage_edit',
    Component: lazyLoad(() => import('../pages/PageSetUp/PageManage/PageManageEdit/PageManageEdit'))
  }
]


const getRoute = () => (
  <div>
    <Switch>
      {
        route.map((item, index) => {
          return <Route
            key={'route' + index}
            path={item.path}
            exact
            component={item.Component}
          />
        })
      }
      <Redirect from="/" to="Login"/>
    </Switch>
  </div>
)

export {route, getRoute}
