/*
 * @Author: 孙兴隆
 * @description 接口文件
 */
import request from '../../../../api/index';

export default class {
  //获取分类数据列表
  static EditDateList = (params) => {
    return request('get', `article/edit`, params)
  }

  //获取分类数据列表
  static SaveDateList = (params) => {
    return request('post', `article/save`, params)
  }

}

