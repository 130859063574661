import request from '~api/index';

export default class {
  // 根据id获取详情
  static getDetail = (params) => {
    return request('GET', `page/get/${params.id}`)
  }
  
  // 保存
  static save = (params) => {
    return request('POST', `page/save`, params)
  }
}