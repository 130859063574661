/*
 * @Author: 孙兴隆
 * @description Mod层处理函数数据
 */

import {observable, action, toJS} from 'mobx'
import Serv from './UpLoadServ'
import {message} from "antd/lib/index";

class UpLoadMod {
  @observable state = {}

  @action UpLoadFile = async (params,Upload) => {
    try {
      const {data, data: {statusCode}} = await Serv.UpLoadFile(params,Upload);
      if (statusCode == 1) {
        return data;
      } else {
        message.error(data.message)
      }
    } catch (error) {
      console.log(error)
    }
  }
}

const upLoadMod = new UpLoadMod()
export default upLoadMod
