import { observable, action } from 'mobx';
import Serv from './PropertyListServ';
import { message } from "antd/lib/index";



class PropertyListMod {
  @observable state = {}

  @action
  deleteProperty = async (params) => {
    try {
      const { data, data: { result, statusCode } } = await Serv.deleteProperty(params);
      if (statusCode == 1) {
        message.success(data.message)
        return true
      } else {
        message.error(data.message)
      }
    } catch (error) {
      console.log(error)
    }
  }
}

const propertyListMod = new PropertyListMod();
export default propertyListMod;