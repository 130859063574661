/*
 * @Author: 孙兴隆
 * @description 接口文件
 */
import request from '../../../../api/index'

export default class {
  //获取系统列表
  static getSystemList = (params) => {
    return request('get', `role/edit`, params)
  }
  //重置密码
  static ResetPass = (params) => {
    return request('post', `employee/reset`, params)
  }
  //启/禁用
  static ChangeStatus = (params) => {
    return request('post', `employee/updateState`, params)
  }

  // 保存
  static update = (params) => {
    return request('post', `role/save`, params)
  }
}

