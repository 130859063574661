/*
 * @Author: 孙兴隆
 * @description Mod层处理函数数据
 */

import { observable, action, toJS } from 'mobx'
import Serv from './ArticleCategoryServ'
import { message } from "antd/lib/index";

class ArticleCategoryMod {
    @observable state = {
      editDtl: {}
    }

    @action getTreeList = async () => {
        try {
            const { data, data: { statusCode } } = await Serv.getTreeList();
            if (statusCode == 1) {
                return data;
            } else {
                message.error(data.message)
            }
        } catch (error) {
            console.log(error)
        }
    }

    // 编辑
    edit = async(params) => {
      try {
        const { data, data: { statusCode } } = await Serv.edit(params);
        if(statusCode == 1){
          this.state.editDtl = data.result;
          // this.state.editDtl.attributeSetId = 89;
          let obj = data.result.attributeSetSelectList.filter(item => item.key == this.state.editDtl.attributeSetId );
          this.state.editDtl.attributeSetSelectList = this.state.editDtl.attributeSetSelectList.map(item => {
            return {
              label: item.value,
              value: item.key
            }
          })
          this.state.editDtl.attributeSetId = obj.length ? this.state.editDtl.attributeSetId : null;

          return this.state.editDtl;
        } else {
          message.error(data.message)
        }
      } catch(error){
        console.log(error)
      }
    }

    // 添加
    add = async(params) => {
      try{
        const { data, data: { statusCode } } = await Serv.add(params);
        if(statusCode == 1){
          message.success(data.message);
          return true;
        } else {
          message.error(data.message)
        }
      } catch(error){
        console.log(error)
      }
    }

    // 删除
    delete = async(params) => {
      try{
        const { data, data: { statusCode } } = await Serv.delete(params);
        if(statusCode == 1){
          message.success(data.message)
          return true;
        } else {
          message.error(data.message)
        }
      } catch(error){
        console.log(error)
      }
    }
}

const articleCategoryMod = new ArticleCategoryMod()
export default articleCategoryMod
