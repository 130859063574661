/*
 * @Author: 孙兴隆
 * @description Mod层处理函数数据
 */

import {observable, action} from 'mobx'
import Serv from './Api'

class Mod {
  @observable state = {
    name: '模板mod',
    title: '模板',
    obj: {
      name: '包含对象'
    },
    data: ''
  }

  @action getApi = async (params) => {
    try {
      const {data} = await Serv.example(params)
      if (!!data) {
        this.state.data = data
        return true
      }
    } catch (error) {
      console.log(error)
    }
  }

}

const mod = new Mod()
export default mod
