/*
 * @Author: 孙兴隆
 * @description 路由拦截器
 */

import React, {Component} from "react";
import {withRouter} from "react-router-dom";
import {inject, observer} from "mobx-react";
import BrandMenu from '../config/mock/BrandMenu.json'
import {toJS} from "mobx/lib/mobx";
import {isEmpty} from 'loadsh'
import qs from 'qs'

@inject("IndexMod")
@observer
@withRouter
class RouterGuard extends Component {
  constructor(props) {
    super(props);
    this.store = this.props.IndexMod;
    this.state = {
      newLocation: {}
    }
  }

  init() {
    let {location, location: {state = {}, query = {}, pathname, search}} = this.props;
    // 解决路由传参页面刷新问题，将参数存入sessionStorage中
    let locaTionDate = {}
    if (!search) {        //第一次进入时间，判断search query state为空时，进入新增页面
      if (isEmpty(query) && isEmpty(state)) {
        locaTionDate = {state: {}, query: {}}
      } else {
        //当第一次进入时，search为空，但携带参数不为空。替换路由添加参数，使刷新时进入下面判断
        this.props.history.replace(`${pathname}?SXL=edit`)
        sessionStorage.setItem(location.pathname, JSON.stringify({state, query, pathname}))
      }
    } else {
      //路由替换后 search拥有参数，如果拥有SXL参数，则参数已经储存，需获取赋值Location
      const {SXL} = qs.parse(search.slice(1));
      if (SXL) {
        locaTionDate = JSON.parse(sessionStorage.getItem(location.pathname))
      } else {
        //假设其他路由跳转自带参数,则添加SXL参数。防止再次刷新丢失参数
        sessionStorage.setItem(location.pathname, JSON.stringify({state, query, pathname}))
        this.props.history.replace(`${pathname + search}&SXL=default`);
      }
    }

    const newLocation = {...location, ...locaTionDate}

    return newLocation
  }


  UNSAFE_componentWillMount() {
    const {tagList} = toJS(this.store.state)
    const {pathname} = this.props.history.location
    const token = sessionStorage.getItem('token')

    const newLocation = this.init();

    this.setState({newLocation})

    if (pathname == '/' || pathname == '/Login') {
      if (!!token) {
        this.props.history.push('Home')
      }
    } else {
      if (!!!token) {
        this.props.history.push('Login')
      }
    }

    // 当路径为Login时，不操作标签栏与导航栏选中
    if (pathname !== '/Login' && pathname !== '/') {
      let result = BrandMenu.List.filter((item) => item.routePath == pathname)[0]
      //因可能存在新增保存公用路由。从路径判断改为名称判断
      let flag = tagList.some((item) => item.menuName == result.menuName)
      if (!isEmpty(newLocation.state) && result.menuName.includes('新增')) {
        result.menuName = result.menuName.replace(/新增/, '编辑')
      } else if (isEmpty(newLocation.state) && result.menuName.includes('编辑')) {
        result.menuName = result.menuName.replace(/编辑/, '新增')
      }
      //执行选中操作
      this.store.changeKey(result.key)
      // 当flag为false时，标签栏没有该路由，执行push
      !flag ? this.store.BreadTag(result) : this.store.ReplaceTag(result)

      this.store.changeSelecteKey(result.key)
      this.store.changeOpenKeys(result.opens)
    }
  }

  render() {
    const {newLocation} = this.state;
    let {location, history, Com, ...other} = this.props;

    return <Com location={newLocation} history={history} {...other}></Com>;
  }
}

export default RouterGuard;
