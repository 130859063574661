import { message } from 'antd';
import { action, observable } from 'mobx';
import Serv from './PageManageEditServ'

class PageManageEditMod {
  @observable state = {
    detailData: {}
  }

  @action
  getDetail = async (params) => {
    try {
      const { data, data: { statusCode, result } } = await Serv.getDetail(params);
      if (statusCode == 1) {
        this.state.detailData = result;
      } else {
        message.error(data.message)
      }
    } catch (error) {
      console.log(error)
    }
  }

  // 保存
  save = async(params) => {
    try {
      const { data, data: { statusCode, result } } = await Serv.save(params);
      if(statusCode == 1){
        message.success(data.message);
        return true;
      } else {
        message.error(data.message);
      }
    } catch(error){
      console.log(error)
    }
  }
}

const pageManageEditMod = new PageManageEditMod();

export default pageManageEditMod;