/*
 * @Author: 孙兴隆
 * @description 接口文件
 */
import request from '../../../../api/index'

export default class {
  //获取菜单列表
  static getSystemList = (params) => {
    return request('get', `sysmenu/index`, params)
  }
  //删除数据
  static deleteSys = (params) => {
    return request('get', `sysmenu/delete`, params)
  }
  //获取单个详情
  static getDetails = (params) => {
    return request('get', `sysmenu/edit`, params)
  }
  //保存
  static SavaSys = (params) => {
    return request('post', `sysmenu/save`, params)
  }
}

