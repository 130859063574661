/*
 * @Author: 孙兴隆
 * @description Mod层处理函数数据
 */

import {observable, action, toJS} from 'mobx'
import Serv from './ModuleManageListServ'
import {message} from "antd/lib/index";

class ModuleManageListMod {
  @observable state = {}

  @action CreatModule = async (params) => {
    try {
      const {data, data: {statusCode}} = await Serv.CreatModule(params);
      if (statusCode == 1) {
        message.success(data.message)
        return true;
      } else {
        message.error(data.message)
      }
    } catch (error) {
      console.log(error)
    }
  }

  @action DeleteDateList = async (params) => {
    try {
      const {data, data: {statusCode}} = await Serv.DeleteDateList(params);
      if (statusCode == 1) {
        message.success(data.message)
        return data;
      } else {
        message.error(data.message)
      }
    } catch (error) {
      console.log(error)
    }
  }
}

const moduleManageListMod = new ModuleManageListMod()
export default moduleManageListMod
