/*
 * @Author: 孙兴隆
 * @description 接口文件
 */
import request from '../../../api/index'

export default class {
  static example = (params) => {
    return request('get', `/product/index`, params)
  }
}

