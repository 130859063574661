/*
 * @Author: 孙兴隆
 * @description 接口文件
 */
import request from '../api/index'

export default class {
  //删除管理员
  static getUserSysList = (params) => {
    return request('get', `sysmenu/getRoleMenu`, params)
  }
}

