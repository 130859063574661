import { action, observable } from 'mobx';
import { message } from 'antd';
import Serv from './PageManageListServ';

class PageManageListMod {
  @observable state = {}

  // 删除
  @action deleteData = async (params) => {
    try {
      const { data, data: {statusCode} } = await Serv.deleteData(params);

      if (statusCode == 1) {
        message.success(data.message);
        return true;
      } else {
        message.error(data.message);
      }
    } catch (error) {
      console.log(error)
    }
  }

  // 生成页面
  @action generate = async(params) => {
    try {
      const { data, data: {statusCode} } = await Serv.generate(params);
      if(statusCode == 1){
        message.success(data.message);
        return true;
      } else {
        message.error(data.message);
      }
    } catch (error){
      console.log(error)
    }
  }
}

const pageManageListMod = new PageManageListMod();

export default pageManageListMod;
