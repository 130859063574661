/*
 * @Author: 孙兴隆
 * @description 替换并新增component自带生命周期与函数
 */

import React, {Component} from 'react'

class View extends Component {

  componentWillMount() {
    this.created();
  }

  componentDidMount() {
    this.mounted();
  }

  componentWillUnmount() {
    this.destroyed();
  }


  created() {

  }

  mounted() {
    console.log(22222222)
  }

  destroyed() {

  }

}

export default View
