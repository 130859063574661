/*
 * @Author: 孙兴隆
 * @description 接口文件
 */
import request from '../../../../api/index'

export default class {
  //删除字段列表
  static DeleteTm = (params) => {
    return request('get', `attributeset/delete`, params)
  }
  //获取编辑详情
  static GetDetails = (params) => {
    return request('get', `attributeset/edit`, params)
  }
  //点击保存
  static SaveTm = (params) => {
    return request('post', `attributeset/save`, params)
  }
}
