/*
 * @Author: 孙兴隆
 * @description Mod层处理函数数据
 */

import {observable, action, toJS} from 'mobx'
import Serv from './ContentMainServ'
import {message} from "antd/lib/index";

class ContentMainMod {
  @observable state = {}

  @action getTreeList = async () => {
    try {
      const {data, data: {statusCode}} = await Serv.getTreeList();
      if (statusCode == 1) {
        return data;
      } else {
        message.error(data.message)
      }
    } catch (error) {
      console.log(error)
    }
  }

  @action getDateList = async (params) => {
    try {
      const {data, data: {statusCode}} = await Serv.getDateList(params);
      if (statusCode == 1) {
        return data;
      } else {
        message.error(data.message)
      }
    } catch (error) {
      console.log(error)
    }
  }

  @action DeleteDateList = async (params) => {
    try {
      const {data, data: {statusCode}} = await Serv.DeleteDateList(params);
      if (statusCode == 1) {
        return data;
      } else {
        message.error(data.message)
      }
    } catch (error) {
      console.log(error)
    }
  }
}

const contentMainMod = new ContentMainMod()
export default contentMainMod
