/*
 * @Author: 孙兴隆
 * @description Mod层处理函数数据
 */

import {observable, action} from 'mobx'

class ListTableMod {
  @observable state = {
    biz: {},
    size: 'default',
    newColumns: []
  }

  // @action getApi = async (params) => {
  //   try {
  //     const {data} = await example(params)
  //     if (!!data) {
  //       return true
  //     }
  //   } catch (error) {
  //     console.log(error)
  //   }
  // }

  @action
  saveState(payload) {
    this.state = {...this.state, ...payload};
  }
}

const listTableMod = new ListTableMod()
export default listTableMod
