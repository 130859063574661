import { message } from 'antd';
import { observable, action } from 'mobx';
import Serv from './PropertyEditServ';

class PropertyEditMod {
  @observable state = {
    propertyDtl: {}
  }

  @action
  getPropertyDtl = async (params) => {
    try {
      const { data, data: { statusCode, result } } = await Serv.getPropertyDtl(params);
      if (statusCode == 1) {
        this.state.propertyDtl = result;
        this.state.propertyDtl.entityTypeRespDtos = this.state.propertyDtl.entityTypeRespDtos.map(item => {
          return {
            label: item.entityTypeName,
            value: item.id
          }
        })
        return true;
      } else {
        message.error(data.message)
      }
    } catch (error) {
      console.log(error)
    }
  }

  // 保存
  save = async (params) => {
    try {
      const { data, data: { statusCode, result } } = await Serv.save(params);
      if (statusCode == 1) {
        return true;
      } else {
        message.error(data.message)
      }
    } catch (error) {
      console.log(error)
    }
  }
}

const propertyEditMod = new PropertyEditMod();
export default propertyEditMod;