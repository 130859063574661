/*
 * @Author: 孙兴隆
 * @description Mod层处理函数数据
 */

import {observable, action} from 'mobx'
import Serv from './AccountServ'
import {message} from "antd/lib/index";

class AccountMod {
  @observable state = {}

  @action DeleteAdmin = async (params) => {
    try {
      const {data, data: {statusCode}} = await Serv.DeleteAdmin(params)
      if (statusCode == 1) {
        message.success(data.message)
        return true
      } else {
        message.error(data.message)
      }
    } catch (error) {
      console.log(error)
    }
  }

  @action ChangeStatus = async (params) => {
    try {
      const {data, data: {statusCode}} = await Serv.ChangeStatus(params)
      if (statusCode == 1) {
        message.success(data.message)
        return true
      } else {
        message.error(data.message)
      }
    } catch (error) {
      console.log(error)
    }
  }

  @action ResetPass = async (params) => {
    try {
      const {data, data: {statusCode}} = await Serv.ResetPass(params)
      if (statusCode == 1) {
        message.success(data.message)
        return true
      } else {
        message.error(data.message)
      }
    } catch (error) {
      console.log(error)
    }
  }

}

const accountMod = new AccountMod()
export default accountMod
