/*
 * @Author: 孙兴隆
 * @description 角色管理  Mod层处理函数数据
 */

import {observable, action, toJS} from 'mobx'
import Serv from './RoleEditServ'
import {message} from "antd/lib/index";

class RoleEditMod {
  @observable state = {
    SystemList: [],
    defaultSelect: ['1-2-3', '1-2-4'],
    roleDtl: {}
  }

  @action getSystemList = async (params) => {
    try {
      const {data, data: {statusCode, result}} = await Serv.getSystemList(params)
      if (statusCode == 1) {
        result.roleState = result.roleState == 0 || !result.roleState ? false : true;
        this.state.roleDtl = result;
        
        this.state.SystemList = result.menuQueryRespDtoList.map((item) => {
          if (item.childrenList.length > 0) {
            item.childrenList = item.childrenList.map((TwoItem) => {
              if (TwoItem.childrenList && TwoItem.childrenList.length > 0) {
                TwoItem.childrenList = TwoItem.childrenList.map((threeItem) => {
                  return {title: threeItem.menuName, key: `${item.id}-${TwoItem.id}-${threeItem.id}`}
                })
              }
              return {title: TwoItem.menuName, key: `${item.id}-${TwoItem.id}`, children: TwoItem.childrenList}
            })
          }
          return {title: item.menuName, key: `${item.id}`, children: item.childrenList}
        })

        return true
      } else {
        message.error(data.message)
      }
    } catch (error) {
      console.log(error)
    }
  }

  @action ChangeStatus = async (params) => {
    try {
      const {data, data: {statusCode}} = await Serv.ChangeStatus(params)
      if (statusCode == 1) {
        message.success(data.message)
        return true
      } else {
        message.error(data.message)
      }
    } catch (error) {
      console.log(error)
    }
  }

  @action ResetPass = async (params) => {
    try {
      const {data, data: {statusCode}} = await Serv.ResetPass(params)
      if (statusCode == 1) {
        message.success(data.message)
        return true
      } else {
        message.error(data.message)
      }
    } catch (error) {
      console.log(error)
    }
  }

  // 保存
  update = async(params) => {
    try {
      const {data, data: {statusCode}} = await Serv.update(params);
      if(statusCode == 1){
        return true;
      } else {
        message.error(data.message)
      }
    } catch(error){
      console.log(error)
    }
  }

  resetState = (params) => {
    this.state = {...this.state, ...params}
  }

}

const roleEditMod = new RoleEditMod()
export default roleEditMod
