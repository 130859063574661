import { observable, action } from 'mobx'
import Serv from './Serv'
import { toJS } from 'mobx'
import { message } from "antd/lib/index";

const state = {
  tagList: [
    {
      "menuName": "首页",
      "routePath": "/Home",
      "key": "Home"
    },
  ],
  activeKey: 'Home',
  selectedKeys: [],
  openKeys: [],
  SystemList: []
}

class IndexMod {

  @observable state = { ...state }

  @action changeName = () => {
    window.app.history.push('Home')
  }
  @action setStore = (e) => {
    this.state.tagList = e
  }
  @action goHref = (e) => {
    console.log(this.props)
  }
  @action changeSelecteKey = (key) => {
    this.state.selectedKeys = [key]
  }
  @action changeOpenKeys = (key) => {
    this.state.openKeys = [key]
  }

  //路由切换时默认选中tab
  @action changeKey = (key) => {
    this.state.activeKey = key
  }

  //当路由切换时默认调用
  @action BreadTag = (e) => {
    this.state.tagList.push(e)
  }

  //当新增编辑重复时 替换路由不执行push
  @action ReplaceTag = (e) => {
    for (let index = 0; index < this.state.tagList.length; index++) {
      const element = this.state.tagList[index];
      if (element.routePath == e.routePath) {
        this.state.tagList[index] = Object.assign(e, element);
        break;
      }
    }
  }

  // 根据路由路径获取BreakTag
  @action getTag = (routePath) => {
    return this.state.tagList.filter((item) => {
      if (item.routePath == routePath) {
        return item;
      }
    })
  }

  //关闭标签栏
  @action DeleteTag = (index, flag) => {
    const { tagList } = this.state
    this.state.tagList.splice(index, 1)
    if (flag) {     //如果关闭当前选项默认跳转上个
      window.app.history.replace(tagList[index - 1].routePath)
    }
  }
  //获取用户权限列表
  @action getUserSysList = async (params) => {
    try {
      const { data, data: { statusCode, result = [] } } = await Serv.getUserSysList(params)
      if (statusCode == 1) {
        this.state.SystemList = result
        return true
      } else {
        // message.error(data.message)
      }
    } catch (error) {
      console.log(error)
    }
  }
}

//  Tool
const indexMod = new IndexMod()
export default indexMod
