/*
 * @Author: 孙兴隆
 * @description 接口文件
 */
import request from '../../../../api/index'

export default class {
  //删除管理员
  static DeleteAdmin = (params) => {
    return request('get', `employee/delete`, params)
  }
  //重置密码
  static ResetPass = (params) => {
    return request('get', `employee/reset`, params)
  }
  //启/禁用
  static ChangeStatus = (params) => {
    return request('get', `employee/updateState`, params)
  }
}

