/*
 * @Author: 孙兴隆
 * @description Mod层处理函数数据
 */

import {observable, action} from 'mobx'
import Serv from './AccountEditServ'
import {message} from "antd/lib/index";

class AccountMod {
  @observable state = {
    SystemList: [],
    defaultSelect: ['1-2-3', '1-2-4'],
    employeeDtl: {}, // 管理员详情
  }

  @action getSystemList = async (params) => {
    try {
      const {data, data: {statusCode, result}} = await Serv.getSystemList(params)
      if (statusCode == 1) {
        this.state.SystemList = result.list.map((item) => {
          if (item.childrenList.length > 0) {
            item.childrenList = item.childrenList.map((TwoItem) => {
              if (TwoItem.childrenList.length > 0) {
                TwoItem.childrenList = TwoItem.childrenList.map((threeItem) => {
                  return {title: threeItem.menuName, key: `${item.id}-${TwoItem.id}-${threeItem.id}`}
                })
              }
              return {title: TwoItem.menuName, key: `${item.id}-${TwoItem.id}`, children: TwoItem.childrenList}
            })
          }
          return {title: item.menuName, key: `${item.id}`, children: item.childrenList}
        })

        return true
      } else {
        message.error(data.message)
      }
    } catch (error) {
      console.log(error)
    }
  }

  @action ChangeStatus = async (params) => {
    try {
      const {data, data: {statusCode}} = await Serv.ChangeStatus(params)
      if (statusCode == 1) {
        message.success(data.message)
        return true
      } else {
        message.error(data.message)
      }
    } catch (error) {
      console.log(error)
    }
  }

  @action ResetPass = async (params) => {
    try {
      const {data, data: {statusCode}} = await Serv.ResetPass(params)
      if (statusCode == 1) {
        message.success(data.message)
        return true
      } else {
        message.error(data.message)
      }
    } catch (error) {
      console.log(error)
    }
  }

  edit = async(params) => {
    try {
      const {data, data: {statusCode, result, message}} = await Serv.edit(params);
      if(statusCode == 1){
        this.state.employeeDtl = result;
        this.state.employeeDtl.isUse = this.state.employeeDtl.isUse || result.roleState == 1 ? true : false;
        if(this.state.employeeDtl.roleRespDtoList && this.state.employeeDtl.roleRespDtoList.length){
          this.state.employeeDtl.roleRespDtoList = this.state.employeeDtl.roleRespDtoList.map(item => {
            return {
              key: item.id.toString(),
              title: item.roleName
            }
          })
        }
        return true
      } else {
        message.error(message)
      }
    }catch(error){
      console.log(error);
    }
  }

  save = async(params) => {
    try {
      const {data, data: {statusCode, result, message}} = await Serv.save(params);
      if(statusCode == 1){
        return true;
      } else {
        message.error(message)
      }
    } catch(error){
      console.log(error)
    }
  }

}

const accountEditMod = new AccountMod()
export default accountEditMod
