/*
 * @Author: 小孙
 * @description 导航Content
 */

import React, {Component} from 'react'        // import声明必须放到 变量之前
import {Layout, Menu, Dropdown} from 'antd';
import {getRoute} from '../../router'           //注入全部路由
import mockMenu from '../../config/mock/Menu.json'
import allMenu from '../../config/mock/BrandMenu.json'
import {withRouter} from 'react-router-dom'
import Bread from '../Bread/Bread'
import {inject, observer} from 'mobx-react'
import {toJS} from 'mobx'

import imgURL from '../../assets/logo192.png';
import nav from './Nav.module.less'
import {DownOutlined} from '@ant-design/icons';

const {Content, Sider, Header} = Layout;
const {SubMenu} = Menu;

@inject('IndexMod')
@withRouter       //子组件无法获取路由参数（不确定）

@observer
class NavMeun extends Component {
  constructor(props) {
    super(props)
    this.state = {
      MenuMore: localStorage.getItem('MenuFlag'),
    }
    this.store = this.props.IndexMod
  }

  //切换导航事件
  goPage = (e) => {
    let path = allMenu.List.filter((item) => item.routePath === e.key)[0]
    this.props.history.push(path.routePath)
    this.setState({select: e.key})
  }

  //头像下拉栏目操作
  ChangeDrop = (e) => {
    if (e.key == 'other') {
      sessionStorage.removeItem('token')
      this.props.history.replace('Login')
    } else if (e.key == 'menu') {
      let MenuFlag = localStorage.getItem('MenuFlag') == 'true' ? true : false
      localStorage.setItem('MenuFlag', !MenuFlag);
      window.location.reload()
    }
  }

  componentWillMount() {
    if (this.props.location.pathname !== '/') {
      this.store.getUserSysList()
    }
  }

  render() {
    const {SystemList} = toJS(this.store.state)
    const {MenuMore} = this.state
    let LeftMenu = [];
    if (SystemList.length > 0) {
      LeftMenu = MenuMore == 'true' ? mockMenu : SystemList
    }

    const
      menu = (
        <Menu onClick={this.ChangeDrop}>
          <Menu.Item key='other'>退出登录</Menu.Item>
          <Menu.Item key='menu'>{MenuMore == 'true' ? '切换线上菜单' : '切换本地菜单'}</Menu.Item>
        </Menu>
      );
    return (
      <Layout>
        <Layout>
          <Sider width={208} className="site-layout-background"
                 style={{height: '100vh', borderRight: 0, overflowY: "auto"}}>
            <div className={nav.logo}>
              <img src={imgURL}></img> Admin Pro
            </div>
            <Menu
              mode="inline"
              defaultSelectedKeys={['/Home']}
              // defaultOpenKeys={['Table']}
              onClick={this.goPage}
              theme="dark"
            >
              {
                !!LeftMenu && LeftMenu.map((item, index) => {
                  if (!item.childrenList) {
                    return <Menu.Item key={item.routePath}>{item.menuName}</Menu.Item>
                  } else if (!!item.childrenList && item.childrenList.some((result, index) => !!result.childrenList)) {
                    return <SubMenu key={`${item.menuName}`} title={item.menuName}>
                      {item.childrenList.map((result) => {
                        if (!!result.childrenList && result.menuType == 1) {
                          return <SubMenu key={result.menuName} title={result.menuName}>
                            {/*因返回数据没有key存在暂时使用menuName替代key*/}
                            {
                              result.childrenList.map((lastChild) => {
                                return <Menu.Item key={lastChild.routePath}>{lastChild.menuName}</Menu.Item>
                              })
                            }
                          </SubMenu>
                        } else {
                          return <Menu.Item key={result.routePath}>{result.menuName}</Menu.Item>
                        }
                      })}
                    </SubMenu>
                  } else {
                    return <SubMenu key={item.key} title={item.menuName}>
                      {
                        item.childrenList.map((result) => {
                          return <Menu.Item key={result.routePath}>{result.menuName}</Menu.Item>
                        })
                      }
                    </SubMenu>
                  }
                })
              }
            </Menu>
          </Sider>
          <Layout>
            <Header className={nav.header}>
              <div className={nav.topHeader}>
                <div className={nav.navDrop}>
                  <Dropdown overlay={menu}>
                    <a onClick={e => e.preventDefault()}>
                      <img src={imgURL}></img> {sessionStorage.getItem('username')} <DownOutlined/>
                    </a>
                  </Dropdown>
                </div>
              </div>
              <Bread/>
            </Header>

            <Content
              style={{
                overflowY: "auto",
                height: "calc(100vh - 84px)",
                boxSizing: "border-box"
              }}
            >
              <div style={{'padding': "0px 10px", 'background': '#edf0f5', 'height': '100%'}}>
                {getRoute()}
              </div>
            </Content>
          </Layout>
        </Layout>
      </Layout>
    )
  }
}

export default NavMeun
