/*
 * @Author: 小孙
 * @description axios重写
 */

import axios from 'axios'
import qs from 'qs'
import config from '../config/config'
import {message} from 'antd'

// 默认请求时间  ms
axios.defaults.timeout = 30 * 1000
axios.defaults.baseURL = config.BaseUrl[`${config.Basics.ENV}`].VICUTU_API
axios.defaults.withCredentials = true


// process.env.API_ROOT
console.log(config.Basics.ENV)
console.log(config.BaseUrl[`${config.Basics.ENV}`].VICUTU_API)

axios.interceptors.request.use((config) => {
  if (config.url !== 'login/login') {
    config.headers['Access-Token'] = sessionStorage.getItem('token')
  }
  if (config.method == 'post') {
    // config.data = qs.stringify(config.data.params)
    config.data = config.data.params
  }
  return config
})

axios.interceptors.response.use(
  response => {
    const {data: {statusCode}} = response
    if (statusCode == 4) {
      message.warning('没有权限')
    }
    if (statusCode == '-4') {
      message.warning('未登录')
      sessionStorage.removeItem('token')
      window.app.history.replace('Login')
    }
    return response
  },
  error => {
    if (error.response.status == '404') {
      message.error('请求地址错误')
    }
    return Promise.reject(error.response)
  }
)


const request = (methods, url, params = {}) => {
  return new Promise((resolve, reject) => {
    if (params == 'post') {
      axios[methods.toLowerCase()](url, params)
        .then(response => resolve(response))
        .catch(error => reject(error))
    } else {
      axios[methods.toLowerCase()](url, {params})
        .then(response => resolve(response))
        .catch(error => reject(error))
    }
  })
}

export default request
