/*
 * @Author: 孙兴隆
 * @description 启动页
 */


import React, {Component} from 'react';
import {Route, Switch, HashRouter as Router, withRouter} from 'react-router-dom'      //在根目录文件全局注入withRouter可在组件内部使用路由函数跳转
import {ConfigProvider} from 'antd'
import {inject, observer} from 'mobx-react'
import 'mobx-react-lite/batchingForReactDom'     //清楚mobx警告
import NavMeun from './components/NavMenu/NavMeun'
import lazyLoad from './router/lazyLoad'       //懒加载组件

import moment from 'moment';
import 'moment/locale/zh-cn';
import zhCN from 'antd/lib/locale/zh_CN';




moment.locale("zh-cn");

@inject('IndexMod')         //store中引入的文件此处都可注入
@observer               //监测数据变化
@withRouter             //全局注入路由信息饿

class App extends Component {
  constructor(props) {
    super(props)
    window.app.history = this.props.history   //将注册的全局对象赋值，保证mod文件中可以使用 this.props.history 函数
    this.store = this.props.IndexMod
  }

  render() {
    return (
      <ConfigProvider locale={zhCN}>
        <div className="App">
          <Router>
            <Switch>
              <Route path="/Login" exact component={lazyLoad(() => import('./components/Login/Login'))}/>
              <Route path="/" component={NavMeun} key={this.props.history}/>
            </Switch>
          </Router>
        </div>
      </ConfigProvider>
    )
  }
}

export default App;
