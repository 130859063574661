/*
 * @Author: 孙兴隆
 * @description Mod层处理函数数据
 */

import {observable, action, toJS} from 'mobx'
import Serv from './ModuleManageEditServ'
import {message} from "antd/lib/index";

class ModuleManageEditMod {
  @observable state = {}

  @action getTreeList = async () => {
    try {
      const {data, data: {statusCode}} = await Serv.getTreeList();
      if (statusCode == 1) {
        return data;
      } else {
        message.error(data.message)
      }
    } catch (error) {
      console.log(error)
    }
  }

  @action SaveDateList = async (params) => {
    try {
      const {data, data: {statusCode}} = await Serv.SaveDateList(params);
      if (statusCode == 1) {
        message.success(data.message)
        return data;
      } else {
        message.error(data.message)
      }
    } catch (error) {
      console.log(error)
    }
  }

  @action EditTreeList = async (params) => {
    try {
      const {data, data: {statusCode}} = await Serv.EditTreeList(params);
      if (statusCode == 1) {
        return data;
      } else {
        message.error(data.message)
      }
    } catch (error) {
      console.log(error)
    }
  }
}

const moduleManageEditMod = new ModuleManageEditMod()
export default moduleManageEditMod
