import request from '../../../../api/index';

export default class {
  // 查询详情
  static getPropertyDtl = (params) => {
    return request('GET', 'attribute/edit', params)
  }

  // 保存
  static save = (params) => {
    return request('POST', 'attribute/save', params)
  }
}
