/*
 * @Author: 孙兴隆
 * @description Mod层处理函数数据
 */

import {observable, action} from 'mobx'
import Serv from './Api'
import {message} from "antd/lib/index";

class Mod {
  @observable state = {
    CodeImage: ''
  }

  @action getCode = async (params) => {
    try {
      const {data} = await Serv.getCode(params)
      if (!!data) {
        this.state.CodeImage = data
        return true
      }
    } catch (error) {
      console.log(error)
    }
  }

  @action Login = async (params) => {
    try {
      const {data, data: {statusCode}} = await Serv.Login(params)
      if (statusCode == 1) {
        return data
      } else {
        message.error(data.message)
      }
    } catch (error) {
      console.log(error)
    }
  }

}

const mod = new Mod()
export default mod
