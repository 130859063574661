/*
 * @Author: 孙兴隆
 * @description 接口文件
 */
import request from '../../../api/index';

export default class {
    //获取系统列表
    static getTreeList = () => {
        return request('get', `article/category/index`)
    }

    // 编辑
    static edit = (params) => {
      return request('get', 'article/category/edit', params)
    }

    // 添加
    static add = (params) => {
      return request('post', 'article/category/save', params)
    }

    // 删除
    static delete = (params) => {
      return request('get', 'article/category/delete', params)
    }
}

