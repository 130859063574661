/*
 * @Author: 孙兴隆
 * @description Mod层处理函数数据
 */

import {observable, action} from 'mobx'
import Serv from './RoleListServ'
import {message} from "antd/lib/index";

class RoleListMod {
  @observable state = {}

  @action DeleteAdmin = async (params) => {
    try {
      const {data, data: {statusCode}} = await Serv.DeleteAdmin(params)
      if (statusCode == 1) {
        message.success(data.message)
        return true
      } else {
        message.error(data.message)
      }
    } catch (error) {
      console.log(error)
    }
  }

  @action ChangeStatus = async (params) => {
    try {
      const {data, data: {statusCode}} = await Serv.ChangeStatus(params)
      if (statusCode == 1) {
        message.success(data.message)
        return true
      } else {
        message.error(data.message)
      }
    } catch (error) {
      console.log(error)
    }
  }

  @action ResetPass = async (params) => {
    try {
      const {data, data: {statusCode}} = await Serv.ResetPass(params)
      if (statusCode == 1) {
        message.success(data.message)
        return true
      } else {
        message.error(data.message)
      }
    } catch (error) {
      console.log(error)
    }
  }

  // 启用 / 禁用角色
  changeRloeStatus = async(params) => {
    try {
      const {data, data: {statusCode}} = await Serv.changeRloeStatus(params);
      if(statusCode == 1){
        message.success(data.message);
        return true;
      } else {
        message.error(data.message);
      }
    } catch(error){
      console.log(error)
    }
  }

  // 删除角色
  deleteRloe = async(params) => {
    try {
      const {data, data: {statusCode}} = await Serv.deleteRloe(params);
      if(statusCode == 1){
        message.success(data.message);
        return true;
      } else {
        message.error(data.message);
      }
    } catch(error){
      console.log(error)
    }
  }
}

const roleListMod = new RoleListMod()
export default roleListMod
