import React from 'react'
import View from '../../components/View'
import {Tabs, Button, Menu, Dropdown} from 'antd';
import {withRouter} from 'react-router-dom'
import {inject, observer} from 'mobx-react'
import {toJS} from 'mobx'
import Style from './Style.module.less'

const {TabPane} = Tabs;

@inject('IndexMod')
@withRouter

@observer
class Bread extends View {
  constructor(props) {
    super(props)
    this.state = {
      tagIndex: 0
    }
    this.store = this.props.IndexMod
  }


  //组件未加载时
  created() {
  }

  //组件加载完成
  mounted() {
  }

  // 组件卸载时  ****路由替换模式为push时 卸载声明周期并不会进入
  destroyed() {
  }

  //tab栏切换时
  onChange = (e) => {
    const {tagList} = toJS(this.store.state)
    let InTag = tagList.filter((item) => item.key == e)
    let path = InTag[0].menuName.includes('编辑') ? `${e}?SXL=edit` : e
    this.props.history.replace(path)
  }
  //tab栏关闭时
  onEdit = (e) => {
    const {tagList} = toJS(this.store.state)
    let index = tagList.map((item) => item.key).indexOf(e)
    let flag = this.props.history.location.pathname === '/' + e ? true : false
    if (e !== 'Home') {
      this.store.DeleteTag(index, flag)
    }
  }

  //标签操作项
  ChangeDrop = (e) => {
    const {tagList} = toJS(this.store.state)
    const {pathname} = this.props.history.location
    if (tagList.length === 1) return;
    let params = []
    if (e.key === 'right') {
      let index = tagList.map((item) => item.routePath).indexOf(pathname)
      tagList.splice(index + 1, tagList.length - 1)
      params = tagList
    } else if (e.key === 'left') {
      let index = tagList.map((item) => item.routePath).indexOf(pathname)
      tagList.splice(1, index - 1)
      params = tagList
    } else if (e.key === 'other') {
      params = [
        {
          "menuName": "首页",
          "routePath": "/Home",
          "key": "Home"
        },
      ]
      this.props.history.replace('Home')
    }
    this.store.setStore(params)
  }

  render() {
    let {tagList, activeKey} = toJS(this.store.state)
    const menu = (
      <Menu onClick={this.ChangeDrop}>
        <Menu.Item key='right'>关闭右侧</Menu.Item>
        <Menu.Item key='left'>关闭左侧</Menu.Item>
        <Menu.Item key='other'>关闭全部</Menu.Item>
      </Menu>
    );
    return (
      <div className={Style.Bread}>
        <div className={Style.Tab}>
          <Tabs
            hideAdd
            onChange={this.onChange}
            activeKey={activeKey}
            type="editable-card"
            onEdit={this.onEdit}
            size={'small'}
          >
            {tagList.map(pane => (<TabPane tab={pane.menuName} key={pane.key}></TabPane>))}
          </Tabs>
        </div>
        <Dropdown overlay={menu} placement="bottomCenter">
          <div className={Style.BrandTab}>
            <Button type="primary" size='small' style={{"float": "right"}}>标签选项</Button>
          </div>
        </Dropdown>
      </div>
    )
  }
}

export default Bread
