/*
 * @Author: 孙兴隆
 * @description Mod层处理函数数据
 */

import {observable, action} from 'mobx'
import Serv from './systemMenuServ'
import {message} from "antd/lib/index";

class SystemMenuMod {
  @observable state = {
    SystemList: []
  }
  //获取系统列表数据
  @action getSystemList = async (params) => {
    try {
      const {data, data: {statusCode, result = []}} = await Serv.getSystemList(params)
      if (statusCode == 1) {
        this.state.SystemList = result
        return true
      } else {
        message.error(data.message)
      }
    } catch (error) {
      console.log(error)
    }
  }
  //删除系统列表数据
  @action deleteSys = async (params) => {
    try {
      const {data, data: {statusCode}} = await Serv.deleteSys(params)
      if (statusCode == 1) {
        message.success(data.message)
        return true
      } else {
        message.error(data.message)
      }
    } catch (error) {
      console.log(error)
    }
  }
  //编辑获取详情接口
  @action getDetails = async (params) => {
    try {
      const {data, data: {statusCode, result = {}}} = await Serv.getDetails(params)
      if (statusCode == 1) {
        // message.success(data.message)
        return result
      } else {
        message.error(data.message)
      }
    } catch (error) {
      console.log(error)
    }
  }

  @action SavaSys = async (params) => {
    try {
      const {data, data: {statusCode, result = {}}} = await Serv.SavaSys(params)
      if (statusCode == 1) {
        message.success(data.message)
        return true
      } else {
        message.error(data.message)
      }
    } catch (error) {
      console.log(error)
    }
  }

}

const systemMenuMod = new SystemMenuMod()
export default systemMenuMod
