/*
 * @Author: 孙兴隆
 * @description 懒加载组件 由index文件传递组件再交付于RouterGuard做路由拦截
 */


import React from 'react';
import RouterGuard from './RouterGuard'

const lazyLoad = (componentfn) => {
  class LazyloadComponent extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        component: null
      }
    }

    async UNSAFE_componentWillMount() {
      const {default: component} = await componentfn();
      this.setState({component})
    }

    render() {
      const C = this.state.component;
      return C ? <RouterGuard Com={C}></RouterGuard> : null;
    }
  }

  return LazyloadComponent;
}

export default lazyLoad
