/*
 * @Author: 孙兴隆
 * @description Mod层处理函数数据
 */

import {observable, action} from 'mobx'
import Serv from './NewEditTemplateServ'
import {message} from "antd/lib/index";

class NewEditTemplateMod {
  @observable state = {
    entityTypeList: [],
    baseAttributeSetList: [],
    detailInfo: {}
  }

  @action ChangeStatus = async (params) => {
    try {
      const {data, data: {statusCode}} = await Serv.ChangeStatus(params)
      if (statusCode == 1) {
        message.success(data.message)
        return true
      } else {
        message.error(data.message)
      }
    } catch (error) {
      console.log(error)
    }
  }

  @action SaveTm = async (params) => {
    try {
      const {data, data: {statusCode}} = await Serv.SaveTm(params)
      if (statusCode == 1) {
        return data
      } else {
        message.error(data.message)
      }
    } catch (error) {
      console.log(error)
    }
  }

  @action GetDetails = async (params) => {
    try {
      const {data, data: {statusCode}} = await Serv.GetDetails(params)
      if (statusCode == 1) {
        const {result, result: {entityTypeList = [], baseAttributeSetList = []}} = data
        this.state.entityTypeList = entityTypeList.map((item) => ({value: item.key, label: item.value}))
        this.state.baseAttributeSetList = baseAttributeSetList.map((item) => ({value: item.key, label: item.value}))
        this.state.detailInfo = result
        return result
      } else {
        message.error(data.message)
      }
    } catch (error) {
      console.log(error)
    }
  }

  @action DeleteTm = async (params) => {
    try {
      const {data, data: {statusCode}} = await Serv.DeleteTm(params)
      if (statusCode == 1) {
        message.success(data.message)
        return true
      } else {
        message.error(data.message)
      }
    } catch (error) {
      console.log(error)
    }
  }

}

const newEditTemplateMod = new NewEditTemplateMod()
export default newEditTemplateMod
