/*
 * @Author: 孙兴隆
 * @description 接口文件
 */
import request from '../../api/index'

export default class {
  static getCode = (params) => {
    return request('get', `login/getValidateCode`, params)
  }
  static Login = (params) => {
    return request('post', `login/login`, params)
  }
}

