const BaseUrl = {
  //本地环境
  development: {
    VICUTU_API: 'http://192.168.150.234:8070/v1',
    VICUTU_API_IMG: 'http://192.168.150.234:8070/v1',
  },
  //测试环境
  test: {
    VICUTU_API: 'http://192.168.150.234:8070/v1',
    VICUTU_API_IMG: 'http://192.168.150.234:8070/v1',
  },
  //打包环境
  production: {
    VICUTU_API: '/v1', // 'http://admin.gelei.cn/v1',
    VICUTU_API_IMG: '/v1', // 'http://admin.gelei.cn/v1',
  },
}

const Basics = {
  ENV: process.env.ENV,       //当前环境变量
  token: sessionStorage.getItem('token'),
  Imgurl: BaseUrl[`${process.env.ENV}`].VICUTU_API_IMG,
  switch: true,
}

let config = Object.assign({ BaseUrl, Basics })


export default config
